import { createElementVNode as _createElementVNode, openBlock as _openBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-center" }
const _hoisted_2 = { class: "mx-4 max-w-4xl justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PhotoList = _resolveComponent("PhotoList")!
  const _component_PhotoListSkeleton = _resolveComponent("PhotoListSkeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: "/photo_gallery" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("span", { class: "d-grid gap-2 flex-1 mb-4" }, [
            _createElementVNode("button", {
              style: {"margin-top":"10px"},
              class: "btn btn-primary",
              type: "button"
            }, [
              _createElementVNode("svg", {
                width: "2em",
                height: "2em",
                viewBox: "0 0 16 16",
                class: "bi bi-arrow-left hover:text-blue-700",
                fill: "currentColor",
                xmlns: "http://www.w3.org/2000/svg"
              }, [
                _createElementVNode("path", {
                  "fill-rule": "evenodd",
                  d: "M5.854 4.646a.5.5 0 0 1 0 .708L3.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0z"
                }),
                _createElementVNode("path", {
                  "fill-rule": "evenodd",
                  d: "M2.5 8a.5.5 0 0 1 .5-.5h10.5a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                })
              ]),
              _createTextVNode(" назад до Фотогалереї ")
            ])
          ], -1)
        ])),
        _: 1
      }),
      (_ctx.arrayImages)
        ? (_openBlock(), _createBlock(_component_PhotoList, {
            key: 0,
            photos: _ctx.arrayImages
          }, null, 8, ["photos"]))
        : (!_ctx.arrayImages && !_ctx.error)
          ? (_openBlock(), _createBlock(_component_PhotoListSkeleton, { key: 1 }))
          : _createCommentVNode("", true)
    ])
  ]))
}